$max-xs: 575px;
$min-sm: 576px;

@mixin floatLeft {
  float: left;
  margin-bottom: 24px;
  margin-right: 24px;
}

@mixin floatRight {
  float: right;
  margin-bottom: 24px;
  margin-left: 24px;
}

.image {
  cursor: pointer;
}

.caption {
  padding-top: 16px;
  font-size: 0.67em;
  line-height: 1.33em;
  text-align: center;
}

.legacy {
  clear: both;
}

.block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.full {
  >img{
    width: 100%;
  }
  width: 100%;
  max-width: 100%;
}

.large {
  >img{
    width: 100%;
  }
  width: 80%;
  max-width: 80%;

  @media (max-width: $max-xs) {
    width: 100%;
    max-width: 100%;
  }
}

.medium {
  >img{
    width: 100%;
  }
  width: 50%;
  max-width: 50%;

  @media (max-width: $max-xs) {
    width: 100%;
    max-width: 100%;
  }
}

.small {
  >img{
    width: 100%;
  }
  width: 33.33%;
  max-width: 33.33%;

  @media (max-width: $max-xs) {
    width: 50%;
    max-width: 50%;
  }
}

.floatLeft.legacy {
  @include floatLeft();
  clear: left;
}

.floatRight.legacy {
  @include floatRight();
  clear: right;
}

.floatLeft.medium {
  @media (min-width: $min-sm) {
    @include floatLeft();
    width: calc(50% - 24px);
    max-width: calc(50% - 24px);
  }
}

.floatLeft.small {
  @include floatLeft();
  width: calc(33.33% - 24px);
  max-width:  calc(33.33% - 24px);

  @media (max-width: $max-xs) {
    width: calc(50% - 24px);
    max-width: calc(50% - 24px);
  }
}

.original {
  max-width: 100%;
  width: auto;
}

.floatLeft.original {
  @include floatLeft();
  max-width: calc(100% - 180px);
}
$max-xs: 600px;

.inlineImage {
  cursor: pointer;
  text-align: center;
  clear: both;
}

.content {
  overflow: auto; //needed for floating images

  font-size: 17px;
  line-height: 1.33em;

  :global {
    .pageBreak {
      height: 2.25em;
      display: block;
      clear: both;
    }
  }

  h1 {
    font-size: 2em;

    @media (max-width: $max-xs) {
      font-size: 1.65em;
    }
  }

  ;

  h2 {
    font-size: 1.65em;

    @media (max-width: $max-xs) {
      font-size: 1.3em;
    }
  }

  ;

  h3 {
    font-size: 1.3em;

    @media (max-width: $max-xs) {
      font-size: 1.2em;
    }
  }

  ;

  h4,
  h5,
  h6 {
    font-size: 1em;
  }

  ;


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.33em;
    margin-top: 1em;
    margin-bottom: 0.5em;

    &:first-child {
      margin-top: 0.25em;
    }
  }

  p,
  .paragraph {
    font-size: 1em;
    // font-family: BodyFont;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    &:nth-child(1) {
      margin-top: 5px;
    }

    &:last-child {
      margin-bottom: 5px;
    }
  }


  strong {
    font-weight: bold;
  }

  img {
    max-width: 100%;
  }

  .videoFile1 {
    max-width: 640px;
    margin: 0 auto;
  }

  .caption {
    padding-top: 16px;
    font-size: 0.67em;
    line-height: 1.33em;
    color: #9D9D9D;
  }

  .videoFile2 {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: auto;
  }

  blockquote {
    display: block;
    padding: 1.2em 0px 1.2em 56px;
    margin: 24px auto 8px auto;
    width: 85%;
    font-style: italic;
    position: relative;
    font-size: 1.25em;
    line-height: 1.4;

    @media (max-width: $max-xs) {
      margin: 8px auto;
      padding: 0.5em 0px 0.5em 40px;
      width: 100%;
      font-size: 1.1em;
    }
  }

  blockquote .paragraph {
    opacity: 0.66;
  }


  blockquote:before {
    content: "\201C";
    font-weight: bold;
    font-size: 3.5em;
    position: absolute;
    left: 8px;
    top: 0px;

    @media (max-width: $max-xs) {
      left: 4px;
      top: 0px;
      font-size: 3em;
    }
  }

}


.socialWallBig {
  font-size: 28px;

  @media (max-width: $max-xs) {
    font-size: 18px;
  }
}
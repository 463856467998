@import "../../colors";

.container {
  background-color: white;
  padding: 20px;

  img {
    max-width: 100%;
  }

  overflow-x: hidden;
}

.date {
  color: $dt-darkBlue;
}

.title {
  font-weight: bold;
  margin: 20px 0;
}

.content {
  padding: 0;
}

.featuredObject {
  max-width: 1000px;
}

.articleContentLink {
  font-size: 1rem;
}

.paragraph {
  font-size: 1rem;
}

.link {
  font-size: 1rem;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.linkTooltip {
  overflow-wrap: break-word;
}

.caption {
  font-size: 1rem;
}

.variationSelectContainer {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;

  select {
    width: auto;
    font-size: 12px;
    margin-left: 10px;
    display: inline;
  }
}

.width1000 {
  max-width: 1000px;
  margin: auto;
}

.width800 {
  max-width: 800px;
  margin: auto;
}

.width600 {
  max-width: 600px;
  margin: auto;
}

.vMargin {
  margin: 30px 0px;

  @media (max-width: 575px) {
    margin: 20px 0px;
  }
}

.vMarginSmall {
  margin: 15px 0px;

  @media (max-width: 575px) {
    margin: 10px 0px;
  }
}

.updateDateText {
  margin-left: 8px;
  font-size: 14px;
  font-style: italic;
  color: #000000;
}
